<template>
  <div class="top_header">
    <div class="bgColor">
      <div class="Ones">
        <p class="hy_survey">本次到店服务内容</p>
        <p class="survey"></p>
        <div class="content">
          <div
            class="divs"
            @click="addtoselect(item)"
            v-for="(item, index) in list"
            :key="index"
          >
            <img src="../../assets/001.png" alt="" />
            <a :class="[item.type === 1 ? 'as1' : 'as']" class="as">{{
              item.projname
            }}</a>
            <p class="ps1">{{ index == 0?'选车评价':'' || index ==1?'销售服务评价':'' || index ==2?'售后服务评价':'' || index == 3?'置换需求评价':'' || index==4?'金融服务评价':''}}</p>
          </div>
          <!-- <div>
                        <img src="../../assets/002.png" alt="">
                    </div>
                    <div>
                        <img src="../../assets/003.png" alt="">
                    </div>
                    <div>
                        <img src="../../assets/004.png" alt="">
                    </div>
                    <div>
                        <img src="../../assets/005.png" alt="">
                    </div>
                    <div>
                        <img src="../../assets/006.png" alt="">
                    </div> -->
        </div>
      </div>
      <!-- <div class="Ones">
                <p class="hy_survey"> 企业调查 </p>
                <p class="survey">尽职尽责 精密行业调查</p>
                <img class="imgSrc1" src="../../assets/mcdc.png" alt="">
                <img src="../../assets/shdc.png" alt="">
            </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      list: [],
    };
  },
  methods: {
    addtoselect(item) {
      console.log(item);
      if (item.type === 0) {
        window.location.href = item.path;
      }
      if (item.type === 1) {
        this.$alert("评价已完成");
        window.location.href = "javascript:;";
        document.querySelector(".as").style.color = "#b1987f"; //设置背景色
      }
    },
  },
  computed: {},
  watch: {},
  async created() {
    let openid = this.$route.query.openid;
    let userId = this.$route.query.userId;
    let result = await this.$API.reqCategoryList(openid, userId);
    if (result.code === 200) {
      this.list = result.trade;
    } else if (result.code === 400) {
      this.$router.push({
        name: "Error_page",
      });
    } else {
      alert(result.msg);
    }
    document.body.removeChild(document.getElementById("Loading"));
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.top_header {
  width: 375px;
  height: 100%;
  background: url(~@/assets/bg3.png);
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
  top: 0px;
  background-size: 100% 100%;

  .bgColor {
    padding-top: 110px;

    .Ones {
      padding: 0px 28px;
      margin-bottom: 36px;

      .survey {
        width: 200px;
        height: 16px;
        font-size: 16px;
        font-family: AlibabaPuHuiTi-Light, AlibabaPuHuiTi;
        font-weight: 300;
        color: #999999;
        line-height: 16px;
        margin-top: 8px;
        margin-bottom: 25px;
      }

      .hy_survey {
        font-size: 35px;
        font-family: AlibabaPuHuiTi-Light, AlibabaPuHuiTi;
        font-weight: 600;
        font-style: italic;
      }

      .content {
        display: flex;
        flex-wrap: wrap;
        display: -webkit-flex;
        /* 新版本语法: Chrome 21+ */
        display: -webkit-box;
        /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
        display: -moz-box;
        /* 老版本语法: Firefox (buggy) */
        display: -ms-flexbox;
        /* 混合版本语法: IE 10 */
        display: flex;
        /* 新版本语法: Opera 12.1, Firefox 22+ */
        width: 100%;

        img {
          display: block;
          margin: 0px 5px 0px 0px;
        }

        .divs {
          position: relative;
          margin-bottom: 5px;

          .as {
            position: absolute;
            left: 10px;
            top: 10px;
            text-decoration: none;
            font-size: 18px;
            color: #8979e9;
          }
          .ps1 {
            width: 100%;
            height: 17px;
            font-size: 12px;
            font-family: AlibabaPuHuiTiR;
            color: #888888;
            line-height: 17px;
            position: absolute;
            left:10px;
            top: 40px;
          }
          .as1 {
            position: absolute;
            left: 10px;
            top: 10px;
            text-decoration: none;
            font-size: 18px;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>

<style >
.el-message-box {
  display: inline-block;
  width: 300px;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.el-message-box__message p {
  text-align: center;
}
</style>